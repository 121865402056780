import React, { Component } from "react";

import { graphql } from 'gatsby'

import Layout from 'components/Page/Support/Layout';
import Article from 'components/Page/Support/Article';

class Doc extends Component {
  
    render() {
      const doc = this.props.data.contentfulDoc
      const relatedDocs = this.props.data.allContentfulDoc
      const categoryTitle = this.props.pageContext.categoryTitle
      const shopify = this.props.pageContext.shopify

      return ( 
        <Layout>
          <Article data={ doc } categoryTitle={ categoryTitle } relatedDocs={relatedDocs} shopify={ shopify } />
        </Layout>
      )
    }
}

export default Doc


// language=GraphQL
export const docQuery = graphql`
  query DocQuery($id: String!, $categoryTitle: String) {
    allContentfulDoc(filter: {category: {title: {eq: $categoryTitle}}, id: {ne: $id}}, limit: 10) {
      nodes {
        title
      }
    }

    contentfulDoc(id: { eq: $id }) {
      title
      body {
        raw
      }
      # blocks { 
      #   ...BlockFields
      # }
    }
  }
`;