import React, { Component } from 'react';
import { Blocks } from 'components/Blocks';

import _ from 'lodash';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import slugify from 'lib/slugify';

import { h1, h3, body } from 'components/Theme/Copy';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Link } from 'gatsby';
import SupportForm from 'components/Blocks/SupportForm';


const Container = styled.div`
  
`;

const ArticleSection = styled.div`
  display:flex;
  justify-content:center;
  flex-direction:column;
  padding-right:6rem;
  width:100%;

  ${media.lessThan("tablet")`
    padding-right:0;
  `}
`;

const Breadcrumbs = styled.div`
  margin-bottom:5rem;
  display:flex;
  flex-direction:row;
`;

const Breadcrumb = styled(Link)`
  text-transform:uppercase;
  text-decoration:underline;
  color:${p => p.theme.cardTextColorDark};
  font-size:1.2rem;
`;

const BreadcrumbCarrot = styled.div`
  color:${p => p.theme.cardTextColorDark};
  font-size:1.2rem;
  line-height:1.6rem;
  margin:0 0.5rem;
`

const TitleSection = styled.div`  
  margin-bottom:3rem;
`;

const MainSection = styled.div`
  display:flex;
  flex-direction: row;
  ${media.lessThan("tablet")`
    flex-direction: column;
  `}
`;


const RelatedArticles = styled.div`
  width:45rem;
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const RelatedArticleLink = styled(Link)`
  display:block;
  color:${p => p.theme.grey};
  font-size:1.4rem;
  margin-bottom:1.5rem;
  border-bottom:0.1rem solid ${p => p.theme.cardBorderColor};
  padding-bottom:1.5rem;
  ${media.lessThan("tablet")`
    margin-bottom:2rem;
    padding-bottom:2rem;
  `}
`;

const BodySection = styled.div`
`;

const Title = styled(h3)`
  font-size:2.8rem;
  color:${p => p.theme.cardTextColor};

  ${media.lessThan("tablet")`
    font-size:2.6rem;
    padding:0;
    text-align:left;
  `}
`;

const RelatedHeader = styled(h3)`
  margin-bottom:3rem;

  ${media.lessThan("tablet")`
    margin:3rem 0;
    border-top:0.1rem solid ${p => p.theme.cardBorderColor};
    padding:3rem 0 0.5rem 0;
  `}
`;

const FormHeader = styled(h3)`
  margin:3rem 0 1.5rem 0;
  border-top:0.1rem solid ${p => p.theme.brandPrimaryTextColor};
  padding:3rem 0 0 0;
`;

const Body = styled(body)`
  font-size:1.8rem;
  color:${p => p.theme.cardTextColor};
  line-height:2.8rem;

  ul {
    list-style-type:disc;
    margin-left:5rem;
  }

  p {
    margin-bottom:1.5rem;
  }
`;

const Form = styled(SupportForm)`
  padding:0;
`;

const Article = ({data, categoryTitle, relatedDocs, shopify}) => {
  return (
    <Container>
      <Breadcrumbs>
        <Breadcrumb to={ `/support` }>Support</Breadcrumb>
        <BreadcrumbCarrot>&#8594;</BreadcrumbCarrot>
        <Breadcrumb to={ `/docs/category/${slugify(categoryTitle)}` }>{categoryTitle}</Breadcrumb>
      </Breadcrumbs>

      <MainSection>
        <ArticleSection>
          <TitleSection>
            <Title>{data.title}</Title>
          </TitleSection>

          <BodySection>
            <Body>
              { documentToReactComponents(JSON.parse(data.body.raw)) }
            </Body>

            { data.blocks && 
              <Blocks data={ data.blocks } shopify={ shopify } />
            }
          </BodySection>

          <FormHeader>Contact us</FormHeader>
          <Form />
        </ArticleSection>

        <RelatedArticles>
          <RelatedHeader>Related Articles</RelatedHeader>
          {relatedDocs.nodes.map((node) => {
            return <RelatedArticleLink to={ `/docs/${slugify(node.title)}` }>{node.title}</RelatedArticleLink>
          })}
        </RelatedArticles>
      </MainSection>

      
    </Container>
  )
}

export default Article