import React, { Component } from 'react'
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';

import { h1 } from 'components/Theme/Copy';
import { Container } from 'components/Theme/Grid';
import MainLayout from "components/layout";

const SupportContainer = styled(Container)`
  flex-direction:column;
  justify-content: center;
  align-items: center;
`;

const SupportCard = styled(Container)`
  flex-direction:column;
  background-color:${p => p.theme.cardBgColor};
  padding:5rem;
  max-width:110rem;

  ${media.lessThan("tablet")`
    padding:1.5rem 1.5rem 3rem 1.5rem;
  `}
`;

const HeadlineCard = styled(Container)`
  padding:5rem;
`;

const Headline = styled(h1)`
  color:${p => p.theme.cardTextColor};
  text-align:center;
  width:100%;
  margin:0;

  ${media.lessThan("tablet")`
    margin:0;
  `}
`;

class Layout extends Component {
  render() {
    return (
      <MainLayout>
        <SupportContainer>
          <HeadlineCard>
            <Headline>Carbon Support</Headline>
          </HeadlineCard>
          <SupportCard neumorphic>
            { this.props.children }
          </SupportCard>
        </SupportContainer>
        
      </MainLayout>
    )
  }
}

export default Layout